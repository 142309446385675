<!--
  记录悬浮气泡
-->
<template>
  <el-popover
    placement="right-start"
    width="270"
    trigger="hover"
    @show="popoverData"
    @hide="popoverHidden"
    popper-class="popperClass"
    ref="popoverXiao"
    :open-delay="500"
  >
    <span slot="reference" @click.prevent="jumpDetail" class="jumpDetailP">
      <span>{{ content }}</span>
    </span>
    <!-- 气泡内容 -->
    <div class="popover_content">
      <!-- 标题 -->
      <div class="popover_header">
        <div class="header_left">
          <div>
            <svg class="icon" aria-hidden="true">
              <use :href="'#icon-' + recordObjData.imgSrc"></use>
            </svg>
          </div>
          <div>
            <p @click="jumpDetail">{{ content }}</p>
          </div>
        </div>
        <div class="header_right">
          <svg aria-hidden="true" slot="reference" @click="popoverHidden">
            <use href="#icon-chahao"></use>
          </svg>
        </div>
      </div>
      <!-- 内容 -->
      <div class="popover_center">
        <!-- 正常的内容 -->
        <div class="center1" v-loading="loading">
          <div
            v-for="(item, index) in fieldNameList"
            :key="index"
            class="center1_div"
          >
            <div>{{ item.labelname }}</div>
            <div
              :class="{
                active: isJump(item.fieldtype, item.fieldname, fieldValueList),
              }"
              @click="
                detailTurn(item.fieldtype, item.fieldname, fieldValueList)
              "
            >
              {{ fieldValueList[getName(item.fieldtype, item.fieldname)] }}
            </div>
          </div>
        </div>
        <!-- 所有相关对象列表 -->
        <div
          class="center2"
          v-for="(item, index) in selcetList"
          :key="index"
          v-loading="loading1"
        >
          <!-- header -->
          <div class="title">
            <div>
              <svg class="icon" aria-hidden="true">
                <use :href="'#icon-' + item.imgSrc"></use>
              </svg>
            </div>
            <div>
              <p @click="relationNameClick(item)">
                {{ item.relationName }}
                <span
                  >({{ item.totalCount > 2 ? "2+" : item.totalCount }})</span
                >
              </p>
            </div>
          </div>
          <!-- 内容 -->
          <div class="center2_content">
            <div class="content-left" v-if="item.detailItemsResValueList">
              <!-- 所有数据，最多两条 -->
              <div
                v-for="(item1, index1) in item.detailItemsResValueList.slice(
                  0,
                  2
                )"
                :key="index1"
              >
                <!-- 每条数据的所有字段 -->
                <div
                  class="each_data"
                  v-for="(item2, index2) in item.detailItemsResNameList"
                  :key="index2"
                >
                  <div v-if="item2.name != 'name'">
                    <div>
                      {{ item2.label }}
                    </div>
                  </div>
                  <div :class="{ nameStyleBigBox: item2.name === 'name' }">
                    <div
                      :class="{
                        active: isJump(item2.type, item2.name, item1),
                        nameStyleBig: item2.name === 'name',
                      }"
                      @click="detailTurnJump(item1, item2.type, item2.name)"
                    >
                      {{ item1[getName(item2.type, item2.name)] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { mapState } from "vuex";
import * as request from "./api.js";
import { consoleSaveTab } from "@/views/home/api.js";
import { owneridIsJump } from "@/config/commonMehods/everyoneJump.js"; // 判断所有人是否跳转权限方法

export default {
  props: {
    content: {
      type: String,
      default: "",
    },
    //记录对象
    recordObj: {
      type: Object,
      default: () => {},
    },
    //固定的对象名称--判断图标（事件和业务机会）
    labelName: {
      type: String,
      default: "",
    },
    knowOwnImg: {
      type: [Boolean, String],
      default: false,
    },
  },
  data() {
    return {
      fieldNameList: [], //悬浮气泡详细信息名称
      fieldValueList: {}, //悬浮气泡详细信息值
      selcetList: [], //悬浮气泡中列表名称
      loading: false, //设置loading初始化页面
      loading1: false,
    };
  },
  mounted() {
    //
  },
  methods: {
    //气泡关闭
    popoverHidden() {
      this.$refs.popoverXiao.doClose();
    },
    /**
     * 服务控制台跳转方法
     * @param {Object} tabObj {id:对象id,value:对象名称}
     *
     */
    consoleJump(tabObj) {
      let path = "";
      // 导航样式为控制台样式
      if (this.$store.state.navigationStyle) {
        // 是
        path = `/commonObjects/console-multi-screen/console-detail/${tabObj.id}/DETAIL?dataId=${tabObj.id}`;
        // 如果当前选中的一级tab为menu说明要打开一级菜单，否则打开二级菜单
        if (this.consoleData.data.level1Id == 'menu') {
          // 主页的最近记录悬浮框会走这里
          let Level1TabItem = {};
          Level1TabItem.id = tabObj.id;
          Level1TabItem.name = tabObj.value;
          // 设置当前选中tab的样式
          this.consoleData.data.level1Id = tabObj.id;
          // 判断是否存在此点击项
          let res = this.consoleData.data.list.some((item) => {
            return item.id == tabObj.id;
          });
          if (!res) {
            // 如果不存在
            this.consoleData.data.list.push(Level1TabItem);
          }
        } else {
          // 详情页悬浮框会走这里
          // 将当前点击数据的id和name 放到当前选中的一级tab下的children里
          // 当前一级tab的对象
          let currentLevelTab = {};
          this.consoleData.data.list.forEach((level1Item) => {
            if (level1Item.id == this.consoleData.data.level1Id) {
              // 设置二级带单选中样式
              level1Item.level2Id = tabObj.id;
              // 当前一级tabid
              currentLevelTab.id = level1Item.id;
              // 当前一级tabname
              currentLevelTab.name = level1Item.name;
              // 当前一级菜单路由  有可能是通过ccdk生成的一级菜单
              currentLevelTab.routerName = level1Item.routerName || "";
              currentLevelTab.routerPath = level1Item.routerPath || "";
              // 当前一级菜单路由参数
              currentLevelTab.params = level1Item.params || "";
              currentLevelTab.query = level1Item.query || "";
              level1Item.children = level1Item.children
                      ? level1Item.children
                      : [];
              // 判断children里是否存在此点击项 不存在再添加避免重复添加
              // 判断是否存在此点击项
              let res = level1Item.children?.some((cItem) => {
                return cItem.id == tabObj.id;
              });
              if (!res) {
                // 如果不存在再push
                level1Item.children.push({
                  id: tabObj.id,
                  name: tabObj.value,
                });
                // 二级tab要展示当前一级tab  判断如果没有当前一级tab 则添加到第一项
                let flag = level1Item.children.some((cItem) => {
                  return cItem.id == this.consoleData.data.level1Id;
                });
                if (!flag) {
                  level1Item.children.unshift(currentLevelTab);
                }
              }
            }
          });
        }
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({ appid: this.$store.state.home.homeApplicationId, opentab: JSON.stringify(this.consoleData) });
      } else {
        path = `/commonObjects/detail/${tabObj.id}/DETAIL`;
      }
      return path;
    },
    // type类型为S Y M的跳转详情--详情信息的跳转
    detailTurn(type, name, item) {
      if (this.isJump(type, name, item)) {
        if (name === "name") {
          let tabObj = {
            id: this.fieldValueList.id,
            value: this.fieldValueList.name,
          };
          let path = this.consoleJump(tabObj);
          this.$router.push({
            path: path,
          });
        } else {
          let tabObj = {
            id: this.fieldValueList[name],
            value: this.fieldValueList[this.getName(type, name)],
          };
          let path = this.consoleJump(tabObj);
          this.$router.push({
            path: path,
          });
        }
      }
    },
    //相关列表详情信息的跳转
    detailTurnJump(item1, type, name) {
      // todo 没有找到入口
      if (this.isJump(type, name, item1)) {
        if (name === "name") {
          this.$router.push({
            path: `/commonObjects/detail/${item1.id}/DETAIL`,
          });
        } else {
          this.$router.push({
            path: `/commonObjects/detail/${item1[name]}/DETAIL`,
          });
        }
      }
    },
    // 跳转方法传回父组件
    jumpDetail() {
      this.$emit("jumpDetail");
    },
    //所有相关对象列表跳转
    relationNameClick(item) {
      let id = this.recordObjData.recordid || this.recordObjData.value;
      this.$router.push({
        path: `/commonObjects/suspension-list/${id}/${item.relatedlistType}`,
        query: {
          relatedlistId: item.relatedlistId,
          layoutId: item.layoutId,
          objId: "",
        },
      });
    },
    //记录卡片-悬浮气泡
    async popoverData() {
      // 清空数据
      this.fieldNameList = [];
      this.selcetList = [];
      //判断图标
      if (this.recordObjData.tabStyle) {
        // 通用对象有label
        if (this.recordObjData.tabStyle) {
          this.recordObjData.imgSrc = this.recordObjData.tabStyle;
        } else {
          this.recordObjData.imgSrc = "hometab";
        }
      } else {
        // 报表对象没有label
        if (this.recordObjData.objid === "lightningreport") {
          this.recordObjData.label = this.$i18n.t("label.visualpage.btype"); //"报表"
          this.recordObjData.imgSrc = this.$i18n.t("label.visualpage.btype"); //"报表"
        } else if (this.recordObjData.objid === "file" || this.knowOwnImg) {
          this.recordObjData.imgSrc = "hometab";
        } else {
          this.recordObjData.imgSrc = this.labelName;
        }
      }

      this.loading = true;
      //获取对象详细信息
      let params = {
        id:
          this.recordObjData.recordid ||
          this.recordObjData.id ||
          this.recordObjData.khmc ||
          this.recordObjData.value, //记录对象的记录ID
        objectApi: "", //对象API--不是必填
        recordtype: "", //	记录类型--不是必填
        operation: "MINI", //操作类型--MINI为悬浮卡片类型
      };
      let res = await request.getDetail(params);
      this.fieldNameList = res.data.fieldList;
      this.fieldValueList = res.data.data;
      this.loading = false;
      this.loading1 = true;
      //获取相关列表对象信息
      let params1 = {
        recordId:
          this.recordObjData.recordid ||
          this.recordObjData.id ||
          this.recordObjData.khmc ||
          this.recordObjData.value,
        isMini: "true",
      };
      let resSlect = await request.getRelationList(params1);
      this.selcetList = resSlect.data;
      let promise = this.selcetList.map(async (item) => {
        if (item.tabStyle) {
          item.imgSrc = item.tabStyle;
        } else {
          item.imgSrc = "hometab";
        }
        let attachementType = "";
        if (item.relationName === this.$i18n.t("FL")) {
          //文件
          attachementType = "file";
        } else if (
          item.relationName === this.$i18n.t("label.weixin.group.description")
        ) {
          // "备注"
          attachementType = "remark";
        }
        let params2 = {
          recordId:
            this.recordObjData.recordid ||
            this.recordObjData.id ||
            this.recordObjData.khmc ||
            this.recordObjData.value,
          layoutId: item.layoutId,
          relatedlistId: item.relatedlistId,
          perPage: "2",
          attachementType: attachementType,
        };

        let detailItemsRes = await request.getDetailRelatedItems(params2);
        if (detailItemsRes.result) {
          item.detailItemsResNameList = detailItemsRes.data.fieldList;
          item.detailItemsResValueList = detailItemsRes.data.data
            ? detailItemsRes.data.data
            : [];
          // 修改数据条数取值
          if(detailItemsRes.data?.data){
              if(detailItemsRes.data.data[0] && detailItemsRes.data.data[0].norecord){
                item.totalCount = 0
              }else {
                item.totalCount = detailItemsRes.data.data.length
              }
            }
        } else {
          item.detailItemsResNameList = [];
          item.detailItemsResValueList = [];
          item.totalCount = "";
        }
      });
      await Promise.all(promise);
      this.loading1 = false;
      this.$forceUpdate();
    },
    // 判断字段是否可跳转
    isJump(fieldtype, fieldname, item) {
      if (
        (fieldtype === "S" && fieldname === "name") ||
        (fieldtype === "Y" && fieldname !== "ownerid") ||
        (fieldname === "ownerid" && owneridIsJump(item[fieldname])) ||
        fieldtype === "M"
      ) {
        /* 可跳转：
            1.字段类型为S，且字段名称为name；
            2.字段类型为Y，但不包含所有人（所有人字段类型为Y）；
            3.字段名称为所有人，且符合所有人跳转规则；
            4.字段类型为M。
        */
        return true;
      } else {
        return false;
      }
    },
    // 显示是否需要加ccname，获取对应的name
    getName(fieldtype, fieldname) {
      if (fieldtype === "Y" || fieldtype === "M") {
        // 字段类型为Y,M即需要加上ccname取值
        return fieldname + "ccname";
      } else if (
        fieldtype === "S" &&
        (fieldname === "whoid" || fieldname === "relateid")
      ) {
        return fieldname + "ccname";
      } else {
        return fieldname;
      }
    },
  },
  computed: {
    ...mapState(["consoleData"]),
    recordObjData() {
      return this.recordObj;
    },
  },
};
</script>
<style lang="scss">
.el-popover.popperClass {
  z-index: 999;
  padding: 0 !important;
  overflow-x: auto;
}
p {
  padding: 0;
  margin: 0;
}
.el-popper.popperClass[x-placement^="right"] .popper__arrow::after {
  border-right-color: #f3f2f2;
}
</style>
<style lang="scss" scoped>
.jumpDetailP {
  outline: none !important;
  color: #006dcc;
  cursor: pointer;
  span {
    display: inline-block;
    // width: 70%; // --没有气泡时
    width: 100%; //--有气泡时
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
//记录悬浮卡片样式
.popover_content {
  display: flex;
  min-height: 98px;
  max-height: 400px;
  flex-direction: column;
  //头固定
  .popover_header {
    display: flex;
    justify-content: space-between;
    height: 45px;
    background: #f3f2f2;
    padding: 0px 2px 0px 10px;
    // border-bottom: 1px solid #dcdcdc !important;
    .header_left {
      display: flex;
      width: calc(100% - 20px);
      div:first-child {
        margin-top: 10px;
        margin-right: 2px;
        svg {
          width: 27px;
          height: 27px;
          margin-top: 0 !important;
        }
      }
      div:nth-child(2) {
        max-width: calc(100% - 29px);
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
          margin-top: 12px !important;
          margin-left: 2px;
          color: #080707;
          font-size: 16px;
          &:hover {
            text-decoration: underline;
          }
        }
        p:hover {
          color: #006dcc;
        }
      }
    }
    .header_right {
      width: 20px;
      height: 20px;
      padding-top: 12px;
      margin-right: 10px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  //中间滚动条部分
  .popover_center {
    flex: 1;
    overflow: auto;
    padding: 0 !important;
    background: #f3f2f2;
    .center1 {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: 1fr 1fr;
      padding: 0 10px;
      padding-bottom: 10px;
      min-height: 80px;
      background: #f3f2f2;
      .center1_div {
        margin-top: 8px !important;
        div:first-child {
          background: #f3f2f2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #666;
          font-size: 12px;
          margin-top: 2px;
        }
        div:last-child {
          background: #f3f2f2;
          color: #080707;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .center2 {
      border-top: 1px solid #dcdcdc !important;
      .title {
        display: flex;
        background: #f3f2f2;
        border-bottom: 1px solid #dcdcdc !important;
        padding-bottom: 10px;
        div:first-child {
          width: 23px;
          height: 23px;
          margin-top: 12px;
          padding-left: 10px;
          svg {
            margin: 0 !important;
            width: 23px;
            height: 23px;
          }
        }
        div:last-child {
          p {
            margin-top: 13px !important;
            margin-left: 16px !important;
            color: #080707;
          }
          p:hover {
            color: #006dcc;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
      .center2_content {
        padding-left: 10px !important;
        width: 100%;
        .content-left {
          display: flex;
          flex-wrap: wrap;
          width: 100% !important;
          div {
            width: 100%;
            &:last-of-type {
              .each_data {
                &:last-of-type {
                  padding-bottom: 8px;
                }
              }
            }
            .each_data {
              margin-top: 4px;
              &:not(:first-child) {
                margin-top: -2px;
              } //除了第一个都
              width: 100%;
              display: flex;
              div:first-child {
                width: 50%;
                font-size: 12px;
                margin-top: 2px;
                div {
                  color: #666;
                  width: 95% !important;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              div:last-child {
                color: #080707;
                width: 50%;
                margin-top: 2px;
                border-bottom: none !important;
                font-size: 12px;
                div {
                  width: 95% !important;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
    .active {
      color: #006dcc !important;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .nameStyleBig {
      font-size: 14px !important;
    }
    ::v-deep .nameStyleBigBox {
      width: 98% !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  //尾固定
  .popover_footer {
    height: 45px;
    color: #006dcc;
    text-align: right;
    font-size: 12px;
    padding-top: 18px;
    padding-right: 18px;
    cursor: pointer;
  }
}
//滚动条样式
::-webkit-scrollbar {
  width: 10px;
  margin-left: 6px;
  // height: 300px;
  background: #f3f6f9;
  border-radius: 2px;
  opacity: 0.6;
  border: 1px solid #dadee4;
}
::-webkit-scrollbar-thumb {
  width: 6px;
  // height: 120px;
  background: #888888;
  border-radius: 4px;
}
</style>
